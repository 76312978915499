import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueNativeSock from 'vue-native-websocket'
import router from './router'

Vue.config.productionTip = false;

//ws://2bs7fpf8ahdewu8a.myfritz.net:7112/ws
//ws://192.168.100.112/ws
//ws://2bs7fpf8ahdewu8a.myfritz.net:7112/ws
Vue.use(VueNativeSock, 'wss://gyro.dm0max.de/ws', {
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
