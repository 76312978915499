import {
    Engine,
    Scene,
    FreeCamera,
    Vector3,
    MeshBuilder,
    StandardMaterial,
    Color3,
    HemisphericLight,
} from "@babylonjs/core";
const myScene = {
    engine: null,
    scene: null,
    createScene: function(canvas) {
        const engine = new Engine(canvas);
        const scene = new Scene(engine);
        myScene.engine = engine;
        myScene.scene = scene;

        const camera = new FreeCamera("camera1", new Vector3(0, 5, 0), scene);
        camera.setTarget(Vector3.Zero());
        camera.attachControl(canvas, true);

        new HemisphericLight("light", Vector3.Up(), scene);

        const boxRed = MeshBuilder.CreateBox("box-red", { size: 1 }, scene);
        const materialRed = new StandardMaterial("box-red-material", scene);
        materialRed.diffuseColor = Color3.Red();
        boxRed.material = materialRed;
        boxRed.position.x = 0;

        engine.runRenderLoop(() => {
            scene.render();
            // changes with each frame here
        });
    },

    getPosition: function(name) {
        const mesh = this.scene.getMeshByName(name);
        if (mesh) {
            return mesh.position;
        }
    },

    setPosition: function(name, x, y, z) {
        const mesh = this.scene.getMeshByName(name);
        if (mesh) {
            mesh.position = new Vector3(x, y, z);
        }
    },

    getRotation: function(name) {
        const mesh = this.scene.getMeshByName(name);
        if (mesh) {
            return mesh.rotation;
        }
    },

    setRotation: function(name, x, y, z) {
        const mesh = this.scene.getMeshByName(name);
        if (mesh) {
            mesh.rotation = new Vector3(x, y, z);
        }
    },
};
export default myScene;
