<template>
  <div class="hello">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{formattedTime}}</v-card-title>
          <v-card-subtitle >Arduino Betriebszeit</v-card-subtitle>
        </v-card>
        <v-card>
          <v-card-title>{{temp}}°C</v-card-title>
          <v-card-subtitle>Temperatur</v-card-subtitle>
        </v-card>
        <v-card>
          <v-card-actions>
            <v-btn :class="gyroPreviewActive ? 'success' : 'grey'" @click="gyroPreviewActive = !gyroPreviewActive">
              {{gyroPreviewActive ? 'Gyro aktiv' : 'Gyro inaktiv'}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <canvas ref="bjsCanvas" width="500" height="400" />
      </v-col>
    </v-row>
    <v-row>
      <v-col style="text-align: center; width: 100%; ">
        <v-card>
          <uplotvue
              key="render-key"
              :data="chartData"
              :options="options"
              ref="charty"
          />
          <v-card-actions class="pt-10 secondary">
            <v-row>
              <v-col>
                <v-btn @click="pauseChart = !pauseChart">{{pauseChart ? 'Fortsetzen' : 'Pause'}}</v-btn>
              </v-col>
              <v-col cols="9">
                <v-slider
                    v-model="pointLimit"
                    :max="1000"
                    :min="10"
                    :step="10"
                    label="Datenpunkte"
                    thumb-label="always"
                >
                </v-slider>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br>
  </div>
</template>

<script>
import myScene from "../scenes/MyFirstScene";
// eslint-disable-next-line no-unused-vars
import uPlot from 'uplot';
import UplotVue from 'uplot-vue';
import 'uplot/dist/uPlot.min.css';
import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
const Chart = Vue.extend({components: {uplotvue: UplotVue}});
export default {
  name: 'HelloWorld',
  components: {uplotvue: UplotVue },
  props: {
    msg: String
  },
  computed: {
    formattedTime() {
      const days = Math.floor(this.millis/(1000*3600)/24);
      const hours = Math.floor(this.millis/(1000*3600)%24);
      const minutes = Math.floor(((this.millis/1000) % 3600) / 60);
      const seconds = Math.floor((this.millis/1000) % 60);
      return `${days} Tage, ${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  methods: {
    moveCube: (name) => {
      let position = myScene.getPosition(name)
      position.x = position.x + 0.5
      myScene.setPosition(name, position.x, position.y, position.z)
    },
    updateObject: function(name) {
      const rotation = this.rotation;
      myScene.setRotation(name, rotation.x, rotation.y, rotation.z);
    },
    addRow: function(time, ax, ay, az, gx, gy, gz, temp) {
      const readings = [time, ax, ay, az, gx, gy, gz, temp];
      if (this.data != null){
        while(this.data[0].length > this.pointLimit){
          for(let i=0; i<readings.length; i++){
            this.data[i].shift();
          }
        }
        for(let i=0; i<readings.length; i++){
          this.data[i].push(readings[i]);
        }
      }
    }
  },
  mounted() {
    const bjsCanvas = this.$refs.bjsCanvas;
    if (bjsCanvas) {
      myScene.createScene(bjsCanvas);
    }


    this.$options.sockets.onmessage = (data) => {
      // eslint-disable-next-line no-unused-vars
      if (this.lastUpdate % 1 === 0){
        let time, ax, ay, az, gx, gy, gz, temp;
        [time,ax,ay,az,gx,gy,gz,temp] = data.data.split(",").map(x => parseFloat(x))

        // calc offsets
        ax = ax - this.position_offset.x
        ay = ay - this.position_offset.y
        az = az - this.position_offset.z
        gx = gx - this.rotation_offset.x
        gy = gy - this.rotation_offset.y
        gz = gz - this.rotation_offset.z

        this.position.x += ax
        this.position.y += ay
        this.position.z += az

        this.position.x *= 0.9
        this.position.y *= 0.9
        this.position.z *= 0.9


        this.sensor = [time,ax,ay,az,gx,gy,gz,temp]
        if ((this.lastDraw+1) < Date.now()) {
          this.lastDraw = Date.now();
          myScene.setPosition("box-red", this.position.x, this.position.y, this.position.z);
          if(this.gyroPreviewActive){
            myScene.setRotation("box-red", gx, gy, gz);
          }else{
            myScene.setRotation("box-red", 0, 0, 0);
          }
          this.position = myScene.getPosition("box-red")
          this.rotation.x = gx
          this.rotation.y = gy
          this.rotation.z = gz
        }
        this.millis = time;
        this.temp = temp;
        this.addRow(time/1000,ax,ay,az,gx,gy,gz,temp);
        if(!this.pauseChart){
          this.chartData = [
            this.data[0],
            this.data[1],
            this.data[2],
            this.data[3],
            this.data[4],
            this.data[5],
            this.data[6],
            this.data[7],
            [time] // workaround for chart issue... otherwise it won't update <.<  https://github.com/vuejs/vue/issues/2164
          ];
        }
      }
      this.lastUpdate++;
    }
  },
  data() {
    return {
      millis: 0,
      position: {x: 0, y: 0, z: 0, combined: 0},
      position_offset: {x: 0.66, y: -0.474, z: -9.55},
      rotation: {x: 0, y: 0, z: 0, combined: 0},
      rotation_offset: {x: -0.075, y: 0.03, z: -0.005},
      sensor: null,
      temp: 0,
      lastUpdate: 0,
      lastDraw: 0,
      chartData: [],
      pauseChart: false,
      gyroPreviewActive: false,
      pointLimit: 500,
      data: [
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          []
      ],
      options: {
        title: "Live Daten",
        width: 800,
        height: 300,
        series: [
          {
            label: "Date"
          },
          {
            label: "ax",
            points: { show: false },
            stroke: "blue",
          },
          {
            label: "ay",
            points: { show: false },
            stroke: "green",
          },
          {
            label: "az",
            points: { show: false },
            stroke: "red",
            show: false,
          },
          {
            label: "gx",
            points: { show: false },
            stroke: "orange",
            show: false,
          },
          {
            label: "gy",
            points: { show: false },
            stroke: "yellow",
            show: false,
          },
          {
            label: "gz",
            points: { show: false },
            stroke: "brown",
            show: false,
          },
          {
            label: "Temp",
            points: { show: false },
            stroke: "black",
            show: false,
          }
        ],
        plugins: [],
        scales: { x: { time: false } }
      },
    }
  }
}
</script>

<style>
.uplot {
  margin-left: auto;
  margin-right: auto;
}
</style>
