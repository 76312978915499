<template>
  <div class="hello">
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{millis/1000}}</v-card-title>
          <v-card-subtitle>Sekunden (Arduino Laufzeit)</v-card-subtitle>
        </v-card>
        <v-card>
          <v-card-title>{{temp}}°C</v-card-title>
          <v-card-subtitle>Temperatur</v-card-subtitle>
        </v-card>
        <v-card>
          <v-card-actions>
            <v-btn :class="gyroPreviewActive ? 'success' : 'grey'" @click="gyroPreviewActive = !gyroPreviewActive">
              {{gyroPreviewActive ? 'Gyro aktiv' : 'Gyro inaktiv'}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="text-align: center; width: 100%; ">
        <v-card>
          <uplotvue
              key="render-key"
              :data="chartData"
              :options="options"
              ref="charty"
          />
          <v-card-actions class="pt-10 secondary">
            <v-row>
              <v-col>
                <v-btn @click="pauseChart = !pauseChart">{{pauseChart ? 'Fortsetzen' : 'Pause'}}</v-btn>
              </v-col>
              <v-col cols="9">
                <v-slider
                    v-model="pointLimit"
                    :max="1000"
                    :min="10"
                    :step="10"
                    label="Datenpunkte"
                    thumb-label="always"
                >
                </v-slider>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import uPlot from 'uplot';
import UplotVue from 'uplot-vue';
import 'uplot/dist/uPlot.min.css';
import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
const Chart = Vue.extend({components: {uplotvue: UplotVue}});
export default {
  name: 'ATV',
  components: {uplotvue: UplotVue },
  props: {
    msg: String
  },
  methods: {
    addRow: function(time, ax, ay, az, gx, gy, gz, temp) {
      const readings = [time, ax, ay, az, gx, gy, gz, temp];
      if (this.data != null){
        while(this.data[0].length > this.pointLimit){
          for(let i=0; i<8; i++){
            this.data[i].shift();
          }
        }
        for(let i=0; i<8; i++){
          this.data[i].push(readings[i]);
        }
      }
    }
  },
  mounted() {
    this.$options.sockets.onmessage = (data) => {
      // eslint-disable-next-line no-unused-vars
      if (this.lastUpdate % 10 === 0){
        let time, ax, ay, az, gx, gy, gz, temp;
        [time,ax,ay,az,gx,gy,gz,temp] = data.data.split(",").map(x => parseFloat(x))
        this.sensor = [time,ax,ay,az,gx,gy,gz,temp]
        this.millis = time;
        this.temp = temp;
        this.addRow(time/1000,ax,ay,az,gx,gy,gz,temp);
        if(!this.pauseChart){
          this.chartData = [
            this.data[0],
            this.data[1],
            this.data[2],
            this.data[3],
            this.data[4],
            this.data[5],
            this.data[6],
            this.data[7],
            [time] // workaround for chart issue... otherwise it won't update <.<  https://github.com/vuejs/vue/issues/2164
          ];
        }
      }
      this.lastUpdate++;
    }
  },
  data() {
    return {
      millis: 0,
      position: {x: 0, y: 0, z: 0},
      rotation: {x: 0, y: 0, z: 0},
      sensor: null,
      temp: 0,
      lastUpdate: 0,
      lastDraw: 0,
      chartData: [],
      pauseChart: false,
      gyroPreviewActive: false,
      pointLimit: 500,
      data: [
          [],
          [],
          [],
          [],
          [],
          [],
          [],
          []
      ],
      options: {
        title: "Chart",
        width: 800,
        height: 300,
        series: [
          {
            label: "Date"
          },
          {
            label: "ax",
            points: { show: false },
            stroke: "blue",
          },
          {
            label: "ay",
            points: { show: false },
            stroke: "green",
          },
          {
            label: "az",
            points: { show: false },
            stroke: "red",
          },
          {
            label: "gx",
            points: { show: false },
            stroke: "orange",
          },
          {
            label: "gy",
            points: { show: false },
            stroke: "yellow",
          },
          {
            label: "gz",
            points: { show: false },
            stroke: "brown",
          },
          {
            label: "Temp",
            points: { show: false },
            stroke: "black",
          }
        ],
        plugins: [],
        scales: { x: { time: false } }
      },
    }
  }
}
</script>

<style>
.uplot {
  margin-left: auto;
  margin-right: auto;
}
</style>
